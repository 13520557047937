import React from "react"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField"
import { navigate } from "gatsby"
import { useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import Dropzone from "react-dropzone"
import * as styles from "./styles.module.scss"
import "./styles.scss"
const JobFormpage = () => {
	const [fileNames, setFileNames] = useState([])
	const handleDrop = (acceptedFiles) => {
		setFileNames(acceptedFiles.map((file) => file.name))
	}

	return (
		<>
			<Container className="py-5">
				<Row className={styles.applyborder}>
					<Form>
						<Row>
							<Col>
								<h2 className={styles.getapply}>
									You are applying for <span className={styles.projectname}>Node.Js Developer</span>
								</h2>
							</Col>
						</Row>
						<Row className="m-5" xs={1} md={2}>
							<Box sx={{ minWidth: 100 }} style={{ width: "100%" }}>
								<FormControl fullWidth>
									<Row>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														First Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Last
														Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="email"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Phone No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>

										<Col md={6} className="py-4 px-md-5 py-md-4">
											<FormLabel
												id="demo-radio-buttons-group-label"
												sx={{
													"& .MuiFormLabel": {
														marginRight: "20px",
														fontSize: "20px",
													},
												}}
											>
												{<span className="radioHeading">Are You Currently Working?</span>}
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												name="radio-buttons-group"
												className="d-flex flex-row"
											>
												<FormControlLabel
													value="true"
													control={<Radio size="medium" />}
													label={<span className="radioHeading">Yes</span>}
													sx={{
														className: "MuiFormControlLabel-label Mui-checked",
													}}
												/>
												<FormControlLabel
													value="false"
													control={<Radio size="medium" />}
													label={<span className="radioHeading">No</span>}
													sx={{
														className: "MuiFormControlLabel-label Mui-checked",
													}}
												/>
											</RadioGroup>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														What is the Notice
														Period&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														What is your last working
														day&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4 d-flex">
											<Dropzone
												onDrop={handleDrop}
												className="d-flex align-items:center justify-content-start"
												sx={{
													className: "MuiOutlinedInput-notchedOutline ",
												}}
											>
												{({ getRootProps, getInputProps }) => (
													<div {...getRootProps({ className: "dropzone" })}>
														<input {...getInputProps()} />
														{fileNames.length > 0 ? (
															<p>{fileNames[0]}</p>
														) : (
															<p>Drag'n'drop files, or click to select files</p>
														)}
													</div>
												)}
											</Dropzone>
											{fileNames.length > 0 ? (
												<svg
													style={{ width: "20px", height: "18px" }}
													onClick={() => {
														setFileNames([])
													}}
													className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"
													focusable="false"
													aria-hidden="true"
													viewBox="0 0 24 24"
													data-testid="DeleteIcon"
													tabindex="-1"
													title="Delete"
												>
													<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
												</svg>
											) : (
												""
											)}
										</Col>

										<Col md={12} className="py-4 px-md-5 py-md-4">
											<TextField
												id="standard-multiline-static"
												rows={6}
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												multiline
												fullWidth={true}
												inputProps={{ className: "jobInputProps" }}
												InputLabelProps={{ className: "jobInputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
									</Row>
								</FormControl>
							</Box>
						</Row>

						<div className={styles.divvv}>
							<button
								className={styles.Jobbutton}
								onClick={() => {
									navigate("/jobapply")
								}}
								variant="primary"
								type="submit"
							>
								{/* send message */}
								Apply Now
							</button>
						</div>
					</Form>
				</Row>
			</Container>
		</>
	)
}
export default JobFormpage
