// extracted by mini-css-extract-plugin
export var IptvHeading = "styles-module--IptvHeading--5WUl0";
export var Iptvblock = "styles-module--Iptvblock--1Wtfj";
export var Iptvpic = "styles-module--Iptvpic--80Vd+";
export var Iptvstatic = "styles-module--Iptvstatic--m0f61";
export var Iptvtool = "styles-module--Iptvtool--YDkuA";
export var cs = "styles-module--cs--ufLtf";
export var csParagraph = "styles-module--csParagraph--oTrXb";
export var cs_heading = "styles-module--cs_heading--IWmvM";
export var csmainIptv1 = "styles-module--csmainIptv1--dtnlO";
export var csmainIptvrow = "styles-module--csmainIptvrow--jlz1q";
export var mainIptv = "styles-module--mainIptv--2wi3Y";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";