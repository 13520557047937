import React from "react"
import JobFormpage from "components/pages/JobForm"
import CareerstartPage from "components/pages/careerstart"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import { Helmet } from "react-helmet"

const Jobapply = () => {
	return (
		<>
			<Helmet>
				<title> MercurySols Technologies | Job Apply</title>
			</Helmet>
			<HeaderTwo />
			<div>
				<CareerstartPage />
				<JobFormpage />
			</div>
			<Footer />
		</>
	)
}

export default Jobapply
