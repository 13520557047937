// extracted by mini-css-extract-plugin
export var Jobbutton = "styles-module--Jobbutton--xt6Ru";
export var Jobform_control = "styles-module--Jobform_control--SrSx+";
export var Jobform_control1 = "styles-module--Jobform_control1--RZh1z";
export var applyborder = "styles-module--applyborder--4WALE";
export var divvv = "styles-module--divvv--J4PLb";
export var getapply = "styles-module--getapply--oC7ko";
export var label = "styles-module--label--28K8o";
export var primary = "\"abc\"";
export var projectname = "styles-module--projectname--5wHiy";
export var radiodiv = "styles-module--radiodiv--a1wGu";
export var secondary = "\"dec\"";
export var svg = "styles-module--svg--xFwsP";