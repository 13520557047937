import React from "react"
import * as styles from "./styles.module.scss"
import { Row, Col } from "react-bootstrap"
import InfoBlock from "constants/companyInfo"

const CareerstartPage = () => {
	const height = 130
	return (
		<>
			<div className={styles.csmainIptvrow}>
				<Row className={`m-0 ${styles.cs}`}>
					<Col>
						<h1 className={styles.cs_heading}>Your Career starts Here</h1>
					</Col>
				</Row>
				<Row className={`m-0 ${styles.csmainIptv1}`}>
					<Col className={styles.csParagraph}>
						<p>You can send your resume to {InfoBlock.HrEmail} , and we'll contact you when a new position opens.</p>
					</Col>
				</Row>
			</div>
		</>
	)
}
export default CareerstartPage
